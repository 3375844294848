<template>
  <section class="container mx-auto grid grid-cols-1 gap-10 w-full pb-16" :class="isImage ? 'md:grid-cols-2' : null">
    <div class="hidden md:block" v-if="isImage">
      <img class="object-cover w-full rounded h-96" :src="descripcion.imagen" alt="auditorio">
    </div>
    <div class="flex items-center px-10 md:px-0 pt-16 md:pt-0">
      <div class="w-full">
        <h2 class="text-3xl font-bold mb-7 title relative">{{descripcion.titulo}}</h2>
        <p v-html="descripcion.cuerpo" style="white-space: pre-wrap" align="justify" v-if="patrocinar"></p>
        <p v-html="eventoSeleccionado.descripcion" style="white-space: pre-wrap" align="justify" v-else></p>
        <br>
        <!-- <p class="leading-9" align="justify" v-if="patrocinar">{{descripcion.cuerpo}}</p> -->
        <!-- <p class="leading-9" align="justify" v-else>{{eventoSeleccionado.descripcion}}</p> -->
        <a class="mt-3 text-theme-color inline-flex items-center py-2" :href="descripcion.link" v-if="hasLink">Conocer más
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
            <path d="M5 12h14M12 5l7 7-7 7"></path>
          </svg>
        </a>

        <!-- <div class="absolute bg-text-color-dark right-0 top-0 w-full h-full z-[-1]"> -->
        <!-- </div> -->
      </div>
    </div>
  </section>
</template>

<script>
  import {mapGetters, mapState} from "vuex";

  export default {
    props: {
      descripcion: {
        require: true
      },
      isImage: {
        type: Boolean,
        default: false
      },
      hasLink: {
        type: Boolean,
        default: false
      },
      patrocinar: {
        type:Boolean,
        default: false
      }
    },
    computed: {
      ...mapState(["idEventoActual"]),
      ...mapGetters(["eventoSeleccionado"])
    },
  }
</script>

<style scoped>
  .title::before{
    content: '';
    position: absolute;
    width: 3rem;
    top: -15px;
    border-radius: 20px;
    border: 2px solid var(--theme-color);
  }
</style>
