import _axios from "axios"

//const BASE_URL = "http://localhost:8000/api"; // Local
const BASE_URL = "https://cici.unillanos.edu.co/api"; // Producción

const axios = _axios.create({
    baseURL: BASE_URL,
    timeout: 50000
});

export default {
    getSliders(params = {}) {
        return axios.get("/sliders", { params })
    },
    getSponsors(params = {}) {
        return axios.get("/patrocinadores", { params })
    },
    getEventos(params = {}) {
        return axios.get("/eventos", { params })
    },
    getTematicas(params) {
        return axios.get("/tematicas", { params })
    },
    getSpeakers(params = {}) {
        return axios.get(`/conferencistas/`, {params})
    },
    getInfo(params = {}) {
        return axios.get("/informacion", {params})
    },
    getMemorias(params = {}) {
        return axios.get("/memorias", {params})
    },
    getTrabajos(params = {}) {
        return axios.get("/trabajos", {params})
    },
    getComites(params = {}) {
        return axios.get("/comites", {params})
    },
    getPlantillas(params = {}){
        return axios.get("/plantillas", {params})
    },
    getFechas(params = {}){
        return axios.get("/fechas", {params})
    },
    getTalleres(params = {}){
        return axios.get("/talleres", {params})
    },
    getSocialNetwork(params = {}){
        return axios.get("/redes", {params})
    },
    getProgramacion(params = {}){
        return axios.get("/programacion", {params})
    },
    getParametros(params = {}){
        return axios.get("/parametros", {params})
    },
    getMenu(params = {}){
        return axios.get("/menu", {params})
    },
    getItemMenu(params = {}){
        return axios.get("/item-menu", {params})
    }
}
