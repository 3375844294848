import Vue from "vue";
import Vuex from "vuex";

import API from "@/api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    idEventoActual: null,
    eventos: [],
    loading: false,
  },

  mutations: {
    setIdEventoActual(state, id) {
      state.idEventoActual = id
    },

    setEventos(state, eventos) {
      state.eventos = eventos
    },

    setLoading(state, payload) {
      state.loading = payload
    },
  },

  actions: {},

  modules: {},

  getters: {
    eventoSeleccionado: (state) => {
      return state.eventos.find((evento) => evento.id === state.idEventoActual);
    }
  }
});
