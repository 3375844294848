<template>
  <div>

    <!--
    <button class="text-white bg-theme-color py-5 px-7 rounded-md mt-8" type="button" v-on:click="toggleModal()">
      *Leer despues de pagar*
    </button>
    -->

    <div v-if="isOpen" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
      <div class="relative w-auto my-6 mx-auto max-w-6xl">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
            <h3 class="text-3xl font-semibold">
              {{title}}
            </h3>
            <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" v-on:click="toggleModal()">
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto">
            <p id="modal-cuerpo" v-html="body" style="white-space: pre-wrap" align="justify"></p>
            <!-- <v-p class="my-4 text-slate-500 text-lg leading-relaxed">
              {{ body }}
            </v-p> -->
          </div>
          <!--footer-->
          <div class="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
            <button class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" v-on:click="toggleModal()">
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isOpen" class="opacity-80 fixed inset-0 z-40 bg-black"></div>
  </div>
</template>

<script>
import M from "minimatch";
import {mapGetters} from "vuex"

  export default {
    props: {
      title:{
        type: String,
        default: 'Title'
      },
      body:{
        type: String,
        default: 'Body'
      },
      showModal:{
        type: Boolean,
        default: false
      }
    },
    data(){
        return {
            isOpen: this.showModal
        }
    },  
    methods: {
      toggleModal: function(){
        this.isOpen = !this.isOpen;
      }
    },
    
    computed: {
      ...mapGetters(["eventoSeleccionado"])
    },
  }
</script>

<style>
  #modal-cuerpo a{
    color:blue
  }
</style>