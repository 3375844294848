<template>
  <div class="relative">
    <swiper ref="mySwiper" class="swiper" :options="swiperOption">
      <swiper-slide v-for="slider in sliders" :key="`slider-${slider.id}`">
        <section class="area-hero w-full relative overflow-hidden bg-white">
          <div class="container mx-auto flex w-full h-[calc(100vh-140px)] z-10 text-center md:text-left">
            <div class="flex-1 flex items-center z-10 text-white md:text-text-color-dark">
              <div class="w-full pt-20 px-6">
                <span class="">{{ slider.fecha }}</span>
                <h1 class="md:text-5xl text-4xl font-bold mt-5 leading-tight text-white md:text-text-color-dark">
                  {{ slider.titulo }}</h1>
                <a :href="slider.link" v-if="slider.link">
                  <button class="text-white bg-theme-color py-5 px-7 rounded-md mt-8">{{ slider.boton }}</button>
                </a>
                <a :href="slider.link_secundario" v-if="slider.link_secundario" target="_blank">
                  <button class="text-white bg-theme-color py-5 px-7 rounded-md mt-8" style="margin-left: 5px">{{ slider.boton_secundario }}</button>
                </a>
              </div>
            </div>
            <div class="flex-1 invisible hidden md:block"></div>
          </div>
          <div class="absolute right-0 top-0 md:w-[50%] w-full h-full">
            <img class="object-cover h-full w-full" :src="slider.imagen" alt="auditorio">
            
            <!--
          <div class="absolute right-0 top-0 md:w-[50%] w-full h-full bg-text-color-dark">
            <img class="object-cover h-full w-full mix-blend-overlay" :src="slider.imagen" alt="auditorio">
            -->

          </div>
        </section>
      </swiper-slide>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </swiper>
    <span class="event">{{eventoSeleccionado.nombre.substring(eventoSeleccionado.nombre.length - 4, eventoSeleccionado.nombre.length)}}</span>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  props: {
    sliders: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        centeredSlides: true,
        autoplay: {
          delay: 8000
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
    }
  },
  computed: {
    ...mapState(["idEventoActual"]),
    ...mapGetters(["eventoSeleccionado"]),
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
}
</script>

<style scoped>

.swiper-button-prev:before, .swiper-button-prev:after {
  @apply text-gray-300;
}

.swiper-button-next:before, .swiper-button-next:after {
  @apply text-gray-300;
}

.event {
  word-wrap: break-word;
  width: 35px;
  line-height: 1.4;
  @apply absolute right-0 bottom-[60px] block font-bold text-white z-40 text-center text-2xl bg-theme-color px-2 py-2 rounded-bl-lg rounded-tl-lg;
}
</style>
