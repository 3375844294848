<template>
  <div class="container-navs">
    <div class="container mx-auto px-6 absolute top-7 left-0 right-0 z-20">
      <header class="w-full">
        <div class="main-header flex justify-between items-center relative container mx-auto h-20 md:h-28 w-full bg-white shadow-md" :class="sticky ? 'nav-sticky' : ''">
          <router-link to="/" class="p-4">
            <img class="w-40 md:w-32" :src="informacion.imagen" alt="Logo CTA">
          </router-link>
          <ul class="xl:flex hidden h-full text-sm font-medium">
            <li v-for="(item, index) in nav" :key="item.id || index" class="nav-item-first flex items-center px-4 hover:text-text-color-dark h-full relative">
              <router-link class="flex items-center h-full" :class="item.items && item.items.length ? 'pointer-events-none' : null" :to="item.path" :exact="item.path === '/'">
                <span class="flex items-center">{{ item.nombre }} <ChevronDown v-if="item.items && item.items.length" /></span>
              </router-link>
              <ul v-if="item.items && item.items.length" class="sub-menu">
                <li v-for="(subItem, subIndex) in item.items" :key="subItem.id || subIndex" class="flex border-t-2 shadow-md items-center px-4 hover:text-theme-color bg-white">
                  <router-link class="flex items-center py-4 w-full" v-if="item.path !== '/eventos'" :to="{ path: `${item.path}/${subItem.path}`, query: { evento: idEventoActual ? idEventoActual : '' } }">
                    {{ subItem.nombre }}
                  </router-link>
                  <router-link class="flex items-center py-4 w-full" :class="{ '!text-theme-color': eventoSeleccionado.id === subItem.id }" v-else :to="{ path: '/', query: { evento: subItem.id } }">
                    {{ subItem.nombre }}
                    <span v-if="subItem.actual === '1'" class="actual">Actual</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="nav-item-first flex items-center px-4 hover:text-text-color-dark h-full relative">
              <span class="flex items-center">Eventos <ChevronDown /></span>
              <ul class="sub-menu">
                <li v-for="(evento, index) in eventos" :key="evento.id || index" class="flex border-t-2 shadow-md items-center px-4 hover:text-theme-color bg-white">
                  <a @click="changeEvento(evento)" :class="{ '!text-theme-color': eventoSeleccionado.id === evento.id }" class="flex items-center py-4 w-full">{{ evento.nombre }}</a>
                </li>
              </ul>
            </li>
          </ul>
          <Menu class="block xl:hidden pr-5" @click="toggleMenu" :size="40" />
        </div>
      </header>
    </div>
    <div class="w-full bg-gray-900 h-screen fixed left-0 top-0 bg-opacity-75 z-40" v-if="mobileToggle">
      <cta-nav-mobile :nav="nav" :isActive="mobileToggle" @closeDrawer="toggleMenu" />
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState, mapGetters} from "vuex"

import ChevronDown from 'vue-material-design-icons/ChevronDown.vue';
import Menu from 'vue-material-design-icons/Menu.vue';
import CtaNavMobile from './CtaNavMobile.vue';
  export default {
    name: "CtaNav",
    components: {
      ChevronDown,
      Menu,
      CtaNavMobile
    },

    props: {
      informacion: Object,
      nav: Array,
      eventos: Array
    },

    data() {
      return {
        sticky: false,
        mobileToggle: false,
      };
    },

    computed: {
      ...mapState(["idEventoActual"]),
      ...mapGetters(["eventoSeleccionado"]),
    },

    methods: {
      ...mapMutations(["setIdEventoActual"]),

      handleScroll() {
        if (window.scrollY > 70) {
          this.sticky = true
        }
        else if (window.scrollY < 70) {
          this.sticky = false
        }
      },

      toggleMenu() {
        this.mobileToggle = !this.mobileToggle;
      },

      async changeEvento(evento){
        console.log("Inicia change CtaNav ");

        const currentQuery = (this.$route.query.evento || this.idEventoActual).toString();
        const newQuery = evento.id.toString();

        console.log("Evento activo CtaNav: " + currentQuery);
        console.log("Evento nuevo CtaNav: " + newQuery);

        if (newQuery !== currentQuery) {
          console.log("Ejecuta change CtaNav ");

          // Cambiar el evento actual en el estado de Vuex
          this.setIdEventoActual(evento.id);

          // Redirigir al evento seleccionado
          this.$router.push({ path: '/', query: { evento: evento.id } });

          // Reinicia la pagina por cambio de evento
          window.location.reload();
        }

        console.log("Final change CtaNav ")

      },
    },

    mounted() {
      window.addEventListener('scroll', this.handleScroll);
    },

    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll);
    },
  };

</script>

<style scoped>
.main-header::before {
  content: '';
  left: -10px;
  width: 10px;
  @apply absolute bg-theme-color h-full;
}

.main-header::after {
  content: '';
  right: -10px;
  width: 10px;
  @apply absolute bg-theme-color h-full;
}

.router-link-active {
  @apply text-text-color-dark;
}

.nav-item-first .router-link-active::before {
  @apply absolute bottom-0 left-1/2 w-0 h-0;
  content: '';
  transform: translateX(-50%);
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 6px solid var(--theme-color);
}

.router-link-exact-active {
  @apply text-theme-color;
}

.nav-item-first:hover .sub-menu {
  top: 100%;
  @apply visible opacity-100 transform-gpu translate-y-0;
}

.nav-item-first:hover::before {
  @apply absolute bottom-0 left-1/2 w-0 h-0;
  content: '';
  transform: translateX(-50%);
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 6px solid var(--theme-color);
}

.sub-menu {
  top: 110%;
  @apply flex-col top-28 absolute left-0 border-t-4 border-theme-color opacity-0 invisible translate-y-6;
  min-width: 200px;
}

.nav-sticky {
  @apply fixed left-0 top-0 right-0;
  z-index: 30;
  animation: sticky 1.2s;
}

.actual {
  @apply bg-red-100 text-theme-color rounded-full px-2 ml-auto;
}

@-webkit-keyframes sticky {
  0% {
    top: -200px;
  }

  100% {
    top: 0;
  }
}

@keyframes sticky {
  0% {
    top: -200px;
  }

  100% {
    top: 0;
  }
}
</style>